<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：负责人选择组件
	开始时间：2021-01-15
	开发人员：刘巍骏,康泽鑫
	最后修改：2021-07-07
	备注说明：外部调用使用@SelectedData获取已选数据
-->
<template>
  <div class="List">
    <el-dialog title="负责人" :visible.sync="show_personnelBox" top="8vh" :before-close="colse_personnelBox" width="65%"
      append-to-body>
      <div class="billing_dialog_table" id="wrapper_userTable">
        <!-- 左边分类 -->
        <div class="list_left searchClassfiy">
          <!-- 分类标题 -->
          <div class="classfi_title" @click="getUserData('data',5)">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div class="classfi_list">
            <el-tree :data="departmentData" :props="defaultProps" @node-click="getCurrClassify"
              :expand-on-click-node="false" default-expand-all>
            </el-tree>
          </div>

        </div>

        <!-- 可拉伸框 -->
        <div class="list_handel"></div>

        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" v-model="currSearchVal" placeholder="请输入查询内容">
              <i class="el-icon-search" @click="getUserData('data',2)"></i>
            </div>

            <!-- 新增 -->
            <!-- <div class="operateBtn">
              <el-button type="primary" size="mini">新增员工</el-button>
            </div> -->
          </div>

          <!-- 列表 -->
          <div class="right_list scollStyle">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table :data="currentPersonnelData" id="personnelTable" @select="getSelectedPersonnel"
                @select-all="getSelectedCurrentPersonnel" ref="personnelSelected" :row-key="(row)=> row.user_id"
                height="100%" border style="width: 100%">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="50"></el-table-column>
                <!-- 全选 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="user_name" label="员工姓名" sortable width="180"></el-table-column>
                <el-table-column prop="sex" label="性别" width="80">
                  <template slot-scope="scope">{{ scope.row.sex === 0 ? '男' : '女' }}</template>
                </el-table-column>
                <el-table-column prop="email" label="邮箱" width="200"></el-table-column>
                <el-table-column prop="post" label="职务" width="120"></el-table-column>
                <el-table-column prop="mobile" label="手机号码" width="130"></el-table-column>
                <el-table-column prop="enrol_time" label="入职日期" min-width="120"></el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-sizes="[10, 20, 30, 40,50]" :page-size.sync="currentPageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="totalNum">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_personal">确认</el-button>
        <el-button size="mini" @click="cancel_personal">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入方法
  import api from "@/network/hr/organization";

  export default {
    name: "list_personnel",
    props: {
      // 默认选中的数据(员工id数组)
      defaultSelected: {
        type: Array,
        default () {
          return []
        }
      }
    },
    data() {
      return {
        // Element ui数据
        defaultProps: { //匹配服务器字段
          children: 'children',
          label: 'dept_name'
        },

        //分页相关数据
        currentPage: 1,
        currentPageSize: 20,
        totalNum: 0, //总条数

        //数据
        currentPersonnelData: [], //当前页显示数据
        selectedPersonnelData: [], //已选员工数组
        conditionType: 1, //查询条件
        currClassifyData: {}, //当前选择的部门分类
        currSearchVal: '', //当前输入框数据

        //loading框
        loading: '', //控制显示loading框
      }
    },
    computed: {
      ...mapState({
        show_personnelBox: state => state.commComponent.show_personnelBox, //控制显示员工选择弹框组件
        departmentData: state => state.hr.departmentData, //部门数据
        personnelData: state => state.hr.personnelData, //企业所有员工信息
      }),
    },
    created() {
      //初始化vuex数据
      this.initVuexData();
    },
    mounted() {
      this.$nextTick(()=>{
        //获取用户数据
        if(this.show_personnelBox){
          this.getUserData('data', 5)
        }
      })
    },
    watch: {
      //监听员工选择组件显示
      show_personnelBox(newVal) {
        this.getUserData('data', 5);
        //获取默认数据
        if (newVal && this.defaultSelected.length > 0) {
          let defaultIntIdArr = []
          //转换为int类型数组
          this.defaultSelected.forEach((item, index) => {
            defaultIntIdArr.push(parseInt(item))
          })
          //获取默认选中数据
          this.personnelData.forEach((item, index) => {
            if (defaultIntIdArr.includes(item.user_id)) {
              this.selectedPersonnelData.push(item)
            }
          })
          //获取默认选中
          this.getDefaultSelData();
        }
      }
    },
    methods: {
      ...mapMutations([
        'SHOW_PERSONNELBOX', //控制左侧导航栏展开
        'DEPARTMENTDATA', //将部门信息存入vuex
        'PERSONNELDATA', //将企业员工存入vuex
      ]),
      ...mapActions([
        'getDepartmentTree', //发送获取部门信息请求
        'getAllUserData' //获取企业所有员工信息
      ]),

      /* 获取vuex数据 */
      initVuexData() {
        //获取部门数据
        if (!this.departmentData.length > 0) {
          this.getDepartmentTree()
        }

        //获取员工数据
        if (!this.personnelData.length > 0) { //不存在
          this.getAllUserData();
        }
      },

      /* 获取员工列表信息 */
      getUserData(userData, type) {
        if (type == 2 && this.currSearchVal.trim() == '') {
          console.log('暂无数据')
          return
        }
        //将查询类型存入data
        this.conditionType = type;
        let data = { //公共条件
          condition: this.conditionType, //查询条件
          pageIndex: this.currentPage, //当前页
          pageSize: this.currentPageSize, //页面大小
          value: type == 1 ? userData.dept_id : this.currSearchVal, //搜索框值
        }

        /* 分类查询 */
        if (this.conditionType == 1) { //分类查询
          data.classfiyId = this.currClassifyData.id;
        }

        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#wrapper_userTable", 4, '员工列表信息获取中,请稍候...');
        //发送请求
        api.searchUserDataByClassfiyId(data).then(res => {
          //关闭loading框
          this.loading.close();
          //判断接口返回数据
          if (res.code == 200) {
            //获取员工数据
            this.currentPersonnelData = res.data.rows;
            //获取总记录数
            this.totalNum = res.data.total;
            //获取默认选中
            this.getDefaultSelData();
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 页面数据总量发生变化触发 */
      handleSizeChange(val) {
        //将数据存入列表
        this.currentPageSize = val;
        //获取员工列表数据
        this.getUserData('data', this.conditionType);
      },

      /* 分页页码发生变化触发 */
      handleCurrentChange(val) {
        //将数据存入列表
        this.currentPage = val;
        //获取员工列表数据
        this.getUserData('data', this.conditionType);
      },

      /* 获取当前选中员工分类 */
      getCurrClassify(data) {
        //将所选员工分类数据存入data
        this.currClassifyData = data;
        //查询员工列表数据
        this.getUserData(data, 1);
      },

      /* 获取默认选中的员工数据 */
      getDefaultSelData() {
        //获取已选数据id数组
        let selIdArr = [];
        this.selectedPersonnelData.forEach((item, index) => {
          selIdArr.push(item.user_id)
        })
        //默认选中已选数组
        this.$nextTick(() => {
          this.currentPersonnelData.forEach((item, index) => {
            if (selIdArr.includes(item.user_id)) {
              this.$refs.personnelSelected.toggleRowSelection(item, true);
            }
          })
        })
      },

      /* 获取已选员工(selection:已选数组  row:当前勾选数据) */
      getSelectedPersonnel(selection, row) {
        let hasRowIndex = -1;
        //根据选中的数据获取已选下标
        this.selectedPersonnelData.forEach((item, index) => {
          if (item.user_id == row.user_id) {
            hasRowIndex = index;
          }
        })
        //判断是选中还是取消
        if (hasRowIndex != -1) { //取消
          this.selectedPersonnelData.splice(hasRowIndex, 1)
        } else { //选中
          this.selectedPersonnelData.push(row)
        }
      },

      /* 全选当前页员工*/
      getSelectedCurrentPersonnel(selection) {
        let curDataIdArr = []
        // 获取当前界面全部数据用户id数组
        this.currentPersonnelData.forEach((item, index) => {
          curDataIdArr.push(item.user_id);
        })
        // 判断是取消全选还是全选
        if (selection.length == 0) { //取消全选
          this.selectedPersonnelData.forEach((item, index) => {
            if (curDataIdArr.includes(item.user_id)) {
              this.selectedPersonnelData.splice(index, 1)
            }
          })
        } else { //全选
          this.currentPersonnelData.forEach((item, index) => {
            this.selectedPersonnelData.push(item)
          })
        }
      },

      /* 关闭弹框 */
      colse_personnelBox() {
        this.SHOW_PERSONNELBOX(false)
      },

      /* 提交选择的员工 */
      commit_personal() {
        //将选择的数据传出
        this.$emit("SelectedData", this.selectedPersonnelData);
        //清空已选值
        this.selectedPersonnelData = [];
        this.$refs.personnelSelected.clearSelection();
        //关闭弹框
        this.SHOW_PERSONNELBOX(false)
      },

      /* 取消选择的员工 */
      cancel_personal() {
        //清空已选值
        this.selectedPersonnelData = [];
        this.$refs.personnelSelected.clearSelection();
        this.SHOW_PERSONNELBOX(false)
      },
    }
  }
</script>

<style>
</style>
